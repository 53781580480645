$color1: #046ef2;
$color2: #0a55cb;
$color3: #bfdcff;
$color4: #ffa62f;
$color5: #faeee7;
$color6: #010101;

$colors: $color1,
$color2,
$color3,
$color4,
$color5,
$color6;

// generate .color1 => 12 and .bg-color1 => 12
@for $i from 1 through length($colors) {
    .color#{$i} {
        color: nth($colors, $i);
    }

    .bg-color#{$i} {
        background: nth($colors, $i);
    }
}