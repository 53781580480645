// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'colors';


td {
    vertical-align: middle !important;
}

thead tr {
    text-transform: capitalize !important;
}

label {
    text-transform: capitalize;
}

.select2-container--default .select2-selection--single {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 37px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 37px !important;
}

.active-nav-item {
    background: #28428e;
}

.nav-link {
    &:hover {
        background: lighten($color: #28428e, $amount: 15);
    }
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background-color: lighten($color: #28428e, $amount: 15);
    color: #fff;
}

.me-auto {
    margin-right: 0px !important;
    margin-left: auto !important;
}

.form-group {
    text-align: start;
}

.modal-body {
    text-align: start;
}

.text-start {
    text-align: start;
}

.text-end {
    text-align: end;
}

.border-left-success {
    border-right: 0.25rem solid $color2 !important;
    border-left: 0rem solid $color2 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0px;
    margin: 0px 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    outline: none;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, #0c0c0c));
    background: -webkit-linear-gradient(top, transparent 0%, #0c0c0c 100%);
    background: -moz-linear-gradient(top, transparent 0%, #0c0c0c 100%);
    background: -ms-linear-gradient(top, transparent 0%, #0c0c0c 100%);
    background: -o-linear-gradient(top, transparent 0%, #0c0c0c 100%);
    background: linear-gradient(to bottom, transparent 0%, #0c0c0c 100%);
    box-shadow: inset 0 0 3px transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 1px solid transparent;
    background-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, transparent));
    background: -webkit-linear-gradient(top, transparent 0%, transparent 100%);
    background: -moz-linear-gradient(top, transparent 0%, transparent 100%);
    background: -ms-linear-gradient(top, transparent 0%, transparent 100%);
    background: -o-linear-gradient(top, transparent 0%, transparent 100%);
    background: linear-gradient(to bottom, transparent 0%, transparent 100%);
}

.page-link {
    border-radius: 5px !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    display: none;
}

.me-2 {
    margin-right: 5px;
    margin-left: 0px;
}

.one-line {
    white-space: nowrap;
}

table {
    white-space: nowrap;
}


.bg-gradient-primary {
    background: $color2 !important;
    background: linear-gradient(90deg, $color2 1%, $color1 100%) !important;
}

.active-nav-item,
.nav-link:hover {
    background: darken($color: $color2, $amount: 10);
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background: darken($color: $color2, $amount: 10);
    color: #fff;
}
